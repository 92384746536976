.c-panel-heading {
  display: inline-block;
  @include rem(font-size, 26px);
  @include rem(margin-bottom, 24px);
  line-height: (56 / 48);
  position: relative;
  text-transform: capitalize;

  @include bp-medium {
    @include rem(font-size, 32px);
  }

  @include bp-large {
    display: block;
    @include rem(font-size, 48px);
    @include rem(margin-bottom, 50px);
  }

  &--small {
    @include rem(font-size, 24px);
    line-height: (43 / 36);

    @include bp-large {
      @include rem(font-size, 36px);
    }
  }

  &.u-text-center {
    display: block;
  }
}