/* ==========================================================================
   #GRID
   ========================================================================== */

/* Customisable Grid layout built using Susy by Oddbird */

.o-row {
  @include container();
}

$columnCount: susy-get(columns);

@mixin column-builder($breakpoint: null) {
  @for $i from 0 through $columnCount {
    @if ($breakpoint == null) {
      .o-col-#{$i} {
        @include span($i);

        .o-row--no-gutters & {
          width: ($i / $columnCount) * 100 + %;
        }
      }

      .o-col-push-#{$i} {
        @include push($i);

        .o-row--no-gutters & {
          margin-left: ($i / $columnCount) * 100 + %;
        }
      }

      .o-col-third {
        width: 33.3333%;
      }

    } @else {
      .o-col-#{$breakpoint}-#{$i} {
        @include span($i);

        .o-row--no-gutters & {
          width: ($i / $columnCount) * 100 + %;
        }
      }

      .o-col-#{$breakpoint}-push-#{$i} {
        @include push($i);

        .o-row--no-gutters & {
          margin-left: ($i / $columnCount) * 100 + %;
        }
      }

      .o-col-#{$breakpoint}-third {
        width: 33.3333%;
      }
    }
  }
}

.o-row--no-gutters [class*=col-] {
  margin-right: 0 !important;
}

@include column-builder()

@include bp-small {
  @include column-builder('small')
}

@include bp-medium {
  @include column-builder('medium')
}

@include bp-large {
  @include column-builder('large')
}

@include bp-xlarge {
  @include column-builder('xlarge')
}

@include bp-full {
  @include column-builder('full')
}

[class*=o-col-]:last-child {
  margin-right: 0;
}

.o-row--flex {
  @include bp-medium {
    display: flex;
  }
}