.c-features {
  margin: 32px 0 8px;
  text-align: center;

  @include bp-medium {
    margin: 32px 0;
  }

  @include bp-large {
    text-align: left;
    margin: 86px 0 120px;
  }

  &--no-mb {
    margin-bottom: 0;
  }

  &--center-item {
    text-align: center;
  }

  [class*=o-col-]:last-child .c-features__item {
    @include bp-medium-max {
      margin-bottom: 0;
    }
  }
}

.c-features__item {
  display: inline-block;
  text-align: center;
  @include rem(margin-bottom, 40px);

  @include bp-medium {
    margin-bottom: 0;
    text-align: left;
  }

  .c-features-center-item & {
    @include bp-large {
      display: table;
      margin: auto;
    }
  }

  .c-features--third & {
    margin-left: auto;
    margin-right: auto;
    max-width: 302px;

    @include bp-medium {
      padding-right: 24px;
    }

    @include bp-large {
      padding-right: 0;
    }
  }
}

.c-features__item__name {
  color: $color-text-heading;
  font-family: $font-helvetica-medium;
  font-weight: normal;
  @include rem(font-size, 13px);
  line-height: 1.5;
  @include rem(margin-top, 8px);

  @include bp-small {
    @include rem(font-size, 14px);
  }

  @include bp-medium {
    @include rem(margin-top, 12px);
    @include rem(font-size, 15px);
  }

  @include bp-large {
    @include rem(font-size, 16px);
  }

  @include bp-jumbo {
    @include rem(font-size, 18px);
    @include rem(margin-top, 14px);
  }
}

.c-features__item__description {
  margin-top: 4px;
  margin-top: 12px;
}