.c-team-member-row {
  display: flex;
  flex-direction: column;

  @include bp-small {
    flex-direction: row;
  }

  @include bp-xlarge {
    display: block;
  }

  .c-team-member-image {
    order: 1;
    transform: none !important;
    transition: opacity 0.8s ease-in !important;
    position: relative;
  }

  .c-team-member-content {
    order: 2;
    
    @include bp-small-max {
      margin-bottom: 20px;
    }
      
    @include bp-large {
      &:first-child {
        order: 1;

        +.c-team-member-image {
          order: 2;
        }
      }
    }
  }

  &:last-child .c-team-member {
    padding-bottom: 0;
  }

  + .c-team-member-row {

    @include bp-small {
      padding-top: 40px;
      border-top: 1px solid #f2f2f2;
    }

    @include bp-large {
      padding-top: 0;
      border-top-width: 0;
    }
  }
}


$halfGrid: (
  columns: 8,
  gutters: 13px/74px,
  math: fluid,
);

@include with-layout($halfGrid) {
  .c-team-member {
    padding: 16px 0 24px;

    @include bp-small {
      padding: 0 0 32px 16px;
    }

    @include bp-medium {
      padding: 0 0 32px 24px;
    }

    @include bp-large {
      padding: 48px span(1) + gutter() 16px;
    }

    @include bp-xlarge {
      padding: 74px span(1) + gutter() 16px;
    }

    p:last-child {
      @include bp-medium-max {
        margin-bottom: 0;
      }
    }
  }
}

.c-team-member__name {
  color: $color-text-heading;
  display: inline-block;
  font-weight: 300;
  @include rem(font-size, 22px);
  line-height: (43 / 36);
  @include rem(margin-bottom, 12px);

  @include bp-medium {
    @include rem(font-size, 24px);
  }

  @include bp-large {
    display: block;
    @include rem(font-size, 36px);
    @include rem(margin-bottom, 20px);
  }
}

.c-team-member__role {
  font-weight: bold;
  line-height: 1.5;
  @include rem(margin-bottom, 24px);
  text-transform: uppercase;

  @include bp-large {
    @include rem(margin-bottom, 36px);
  }
}

.c-team-member__icon {
  font-size: 24px;
  color: $color-text-feature;
  transition: all ease-in-out 0.15s;
  text-decoration: none;

  @include bp-medium {
    font-size: 28px;
  }

  @include bp-large {
    font-size: 32px;
  }

  &--mobile {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;

    @include bp-large {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include bp-large {
      display: inline-block;
    }
  }

  &:hover,
  &:focus {
    color: $color-text-feature;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
}

.c-team-member-image__img {
  @include bp-large {
    display: none;
  }

  @include bp-xlarge {
    display: block;
  }
}

.c-team-member-image__bg {
  display: none;

  @include bp-large {
    background-size: cover;
    background-position: center;
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include bp-full {
    display: none;
  }
}