/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-brand-purple-dark;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  text-align: center;

  @include bp-medium {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &.u-fade-up {
    opacity: 1 !important;
    transform: none !important;
    
    &:before {
      background-image: linear-gradient(90deg, $color-brand-purple 9%, $color-brand-purple-dark 91%);
      content: "";
      opacity: 0;
      transition: opacity 1s 0.5s ease;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }

  &.in-view {
    &:before {
      opacity: 1;
    }
  }

  @include bp-large {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

.c-footer-nav__list-item {
  display: inline-block;
  margin: 0 8px;

  @include bp-medium {
    margin: 0 16px;
  }

  @include bp-large {
    margin: 0 24px;
  }

  @include bp-xlarge {
    margin: 0 42px;
  }
}

.c-footer__inner {
  @include inner-container;
  position: relative;
  z-index: 1;

  @include bp-medium {
    .u-fade-up & {
      transform: translateY(20%);
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.31, 0.72, 0.43, 1);
    }
    
    .u-fade-up.in-view & {
      opacity: 1;
      transform: translateY(0);
    }

    .no-js & {
     opacity: 1; 
    }
  }
}