.c-footer-legal {
  margin-bottom: 0;
  margin-top: 24px;

  @include bp-medium {
    margin-top: 32px;
    @include rem(font-size, 13px);
  }

  @include bp-large {
    margin-top: 46px;
    @include rem(font-size, 15px);
  }
}

.c-footer-legal__copyright,
.c-footer-legal__link {
  color: rgba(#fff, 0.33);
}

.c-footer-legal__link {
  font-family: $font-helvetica-medium;
  font-weight: normal;
  text-decoration: none;

  &:hover,
  &:focus {
    color: rgba(#fff, 0.33);
    text-decoration: underline;
  }
}

.c-footer-legal__list-item {
  display: inline-block;
  margin-bottom: 0;

  + .c-footer-legal__list-item:before {
    content: "·";
    color: rgba(#fff, 0.33);
    margin: 0 10px;
  }
}