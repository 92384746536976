$text-color-mobile: rgba(#7A676D, 0.2);
$text-color-desktop: rgba(#7A676D, 0.1);

.c-stat {
  display: flex;
  align-items: baseline;
  justify-content: center;

  &--icon-left {
    @include bp-medium {
      justify-content: flex-start;
    }
  }

  &--icon-margin {
    .c-stat__icon {
      margin-bottom: 10px;
    }
  }
}

.c-stat__text-large {
  color: $text-color-mobile;
  font-weight: bold;
  @include rem(font-size, 38px);
  line-height: 1;
  margin-left: 6px;

  @include bp-small {
    @include rem(font-size, 40px);
  }

  @include bp-medium {
    @include rem(font-size, 44px);
  }

  @include bp-large {
    color: $text-color-desktop;
    @include rem(font-size, 48px);
    margin-left: 12px;
  }

  @include bp-xlarge {
    @include rem(font-size, 64px);
  }

  @include bp-full {
    @include rem(font-size, 72px);
  }
}

.c-features [class*=col-]:first-child {
  .c-stat__text-large {
    letter-spacing: -2px;
  }
  .c-stat__text-small {
    margin-left: 6px;
  }
}

.c-stat__text-small {
  color: $text-color-mobile;
  font-weight: bold;
  @include rem(font-size, 18px);
  line-height: 1;
  margin-left: 4px;

  @include bp-small {
    @include rem(font-size, 20px);
  }

  @include bp-medium {
    @include rem(font-size, 22px);
  }

  @include bp-large {
    color: $text-color-desktop;
    @include rem(font-size, 24px);
  }

  @include bp-xlarge {
    @include rem(font-size, 24px);
  }
}

.c-stat__icon {
  font-size: 25px;
  color: $color-text-feature;
  
  @include bp-medium {
    font-size: 28px;
  }

  @include bp-large {
    font-size: 30px;
  }

  @include bp-xlarge {
    font-size: 32px;
  }

  @include bp-full {
    font-size: 46px;
  }
}