.c-checkbox__input {
  opacity: 0;
  position: absolute;
  left: -9999em;
}

.c-checkbox__stylised {
  background-color: transparent;
  box-sizing: border-box;
  border: 2px solid $color-feature;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  transition: background-color ease 0.15s;
  margin-right: 14px;
  width: 24px;

  .c-checkbox__input:checked ~ .c-checkbox__label & {
    background-color: $color-feature;
  }

  .c-checkbox__label:active & {
    box-shadow: $form-el-active-shadow;
  }
}

.c-checkbox__icon {
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 3px;
  transform: scale(0);
  transition: transform ease 0.1s;

  .c-checkbox__input:checked ~ .c-checkbox__label & {
    transform: scale(1);
  }
}