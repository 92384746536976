$halfGrid: (
  columns: 2,
  gutters: 13px/344px,
  math: fluid,
);

@include with-layout($halfGrid) {
  .c-form__row {
    @include container;
    margin-bottom: 0;
  }

  .c-form__col {
    @include rem(margin-bottom, 10px);
    position: relative;
    @include span(2);

    @include bp-small {
      margin-bottom: gutter();
      @include span(1);
    }

    &.c-form__col--textarea {
      @include span(2);
    }

     &:nth-child(2n+1) {
      @include bp-small {
        margin-right: 0;
      }
    }

    fieldset {
      margin-bottom: 0;
    }

    .o-list-bare,
    .o-list-bare li {
      margin-bottom: 0;
    }
  }
}

.c-form__col--subscribe {
  margin-bottom: 20px;

  @include bp-small {
    margin-right: 0;
    width: 60%;
  }
}

.c-form__col--submit {
  @include bp-small {
    margin-right: 0;
    width: 100%;
  }

  .c-form-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include bp-small {
      float: right;
    }
  }
}

.c-form-text {
  background: $form-input-bg;
  border: $form-input-border;
  border-radius: 3px;
  box-sizing: border-box;
  color: $form-input-focussed-text-color;
  font-family: $font-sans-serif;
  @include rem(font-size, 16px);
  outline: 0;
  padding: 16px 15px 15px;
  vertical-align: top;
  -webkit-appearance: none;
  width: 100%;

  @include bp-xlarge {
    padding: 16px 20px 15px;
  }

  &:active {
    box-shadow: $form-el-active-shadow;
  }

  &:focus {
    border: $form-input-border-focussed;
  }

  @include placeholder {
    color: $form-input-placeholder-text-color;
  }
}

.c-form-btn {
  font-family: $font-helvetica-medium;
  @include rem(font-size, 16px);
  font-weight: normal;
  padding: 10px 54px;

  @include bp-medium {
    @include rem(font-size, 18px);
    padding: 14px 54px 13px;
  }

  @include bp-xlarge {
    @include rem(font-size, 20px);
    padding: 14px 54px 13px;
  }
}

.c-form__col__checkboxes {
  padding-top: 10px;

  @include bp-small {
    padding-top: 6px;
  }

  @include bp-medium {
    padding-top: 10px;
  }
}

.parsley-errors-list {
  background: #be3333;
  border-bottom-left-radius: $global-radius;
  border-top-right-radius: $global-radius;
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;

  li {
    color: #fff;
    font-size: 9px;
    -webkit-font-smoothing: auto;
    line-height: 1;
    margin-bottom: 0;
    padding: 4px 7px 3px;
    text-transform: uppercase;
  }
}