.c-page-intro {
  @include initial-fade-in;
}

.c-page-intro__heading{
  letter-spacing: -0.5px;
}

.c-page-intro__heading,
.c-page-intro__heading h1 {
  color: $color-text-feature;
  @include rem(font-size, 38px);
  font-weight: bold;
  line-height: 1.14;
  margin-bottom: 10px;

  @include bp-small {
    @include rem(font-size, 60px);
  }
  
  @include bp-large {
    @include rem(font-size, 70px);
    margin-bottom: 28px;
  }

  @include bp-xlarge {
    @include rem(font-size, 80px);
    margin-bottom: 32px;
  }

  @include bp-full {
    @include rem(font-size, 100px);
  }
}

.c-page-intro__subheading,
.c-page-intro__subheading h2 {
  color: $color-text-light;
  font-weight: 300;
  @include rem(font-size, 24px);
  line-height: 1.2;
  margin-bottom: 0;

  @include bp-small {
    @include rem(font-size, 30px);
  }

  @include bp-large {
    @include rem(font-size, 34px);
  }

  @include bp-xlarge {
    @include rem(font-size, 40px);
  }
}