.c-panel-icon {
  color: $color-text-feature;
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-right: 16px;

  @include bp-large-max {
    position: relative;
  }

  @include bp-medium {
    font-size: 30px;
  }

  @include bp-large {
    font-size: 36px;
    float: none;
    line-height: 1.4;
    position: absolute;
    transform: translateX(-60px);
  }

  @include bp-full {
    font-size: 44px;
    transform: translateX(-76px);
  }

  &.u-fade-up {
    @include bp-large {
      transform: translateX(-60px) translateY(20%);
    }

    @include bp-full {
      transform: translateX(-76px) translateY(20%);
    }
  }

  &.u-fade-up.in-view {
    @include bp-large {
      transform: translateX(-60px) translateY(0);
    }

    @include bp-full {
      transform: translateX(-76px) translateY(0);
    }
  }

  .icon-speedometer {
    @include bp-large {
      line-height: 1.4;
    }
    
    @include bp-full {
      font-size: 36px;
    }
  }
}