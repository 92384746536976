.c-copy-large {
  @include rem(font-size, 18px);
  line-height: (36 / 24);
  @include rem(margin-bottom, 36px)

  @include bp-large {
    @include rem(font-size, 22px);
    @include rem(margin-bottom, 48px)
  }

  @include bp-jumbo {
    @include rem(font-size, 24px);
    @include rem(margin-bottom, 66px)
  }
}