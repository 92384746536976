@function convert-length($length, $to-unit, $from-context: $base-font-size, $to-context: $from-context) {
  $from-unit: unit($length);

  // Optimize for cases where from and to may accidentally be the same.
  @if $from-unit == $to-unit { @return $length; }
  @if unit($from-context) != 'px' { @warn "Paremeter $from-context must resolve to a value in pixel units."; }
  @if unit($to-context) != 'px' { @warn "Parameter $to-context must resolve to a value in pixel units."; }

  // Fixed ratios
  // https://developer.mozilla.org/en/CSS/length
  // http://dev.w3.org/csswg/css3-values/#absolute-lengths
  $px-per-in: 96px / 1in;
  $px-per-mm: 96px / 25.4mm;
  $px-per-cm: 96px / 2.54cm;
  $px-per-pt: 4px / 3pt;
  $px-per-pc: 16px / 1pc;

  // Variables to store actual convesion ratios
  $px-per-from-unit: 1;
  $px-per-to-unit: 1;

  @if $from-unit != 'px' {
    @if   $from-unit == 'em'  { $px-per-from-unit: $from-context / 1em }
    @else if $from-unit == 'rem' { $px-per-from-unit: $base-font-size / 1rem }
    @else if $from-unit == '%'   { $px-per-from-unit: $from-context / 100% }
    @else if $from-unit == 'ex'  { $px-per-from-unit: $from-context / 2ex }
    @else if $from-unit == 'in'  { $px-per-from-unit: $px-per-in }
    @else if $from-unit == 'mm'  { $px-per-from-unit: $px-per-mm }
    @else if $from-unit == 'cm'  { $px-per-from-unit: $px-per-cm }
    @else if $from-unit == 'pt'  { $px-per-from-unit: $px-per-pt }
    @else if $from-unit == 'pc'  { $px-per-from-unit: $px-per-pc }
    @else if $to-unit == 'ch' or $to-unit == 'vw' or $to-unit == 'vh' or $to-unit == 'vmin' {
      @warn "#{$from-unit} units can't be reliably converted; Returning original value.";
      @return $length;
    }
    @else {
      @warn "#{$from-unit} is an unknown length unit. Returning original value.";
      @return $length;
    }
  }

  @if $to-unit != 'px' {
    @if   $to-unit == 'em'  { $px-per-to-unit: $to-context / 1em }
    @else if $to-unit == 'rem' { $px-per-to-unit: $base-font-size / 1rem }
    @else if $to-unit == '%'   { $px-per-to-unit: $to-context / 100% }
    @else if $to-unit == 'ex'  { $px-per-to-unit: $to-context / 2ex }
    @else if $to-unit == 'in'  { $px-per-to-unit: $px-per-in }
    @else if $to-unit == 'mm'  { $px-per-to-unit: $px-per-mm }
    @else if $to-unit == 'cm'  { $px-per-to-unit: $px-per-cm }
    @else if $to-unit == 'pt'  { $px-per-to-unit: $px-per-pt }
    @else if $to-unit == 'pc'  { $px-per-to-unit: $px-per-px }
    @else if $to-unit == 'ch' or $to-unit == 'vw' or $to-unit == 'vh' or $to-unit == 'vmin' {
      @warn "#{$to-unit} units can't be reliably converted; Returning original value.";
      @return $length;
    }
    @else {
      @warn "#{$to-unit} is an unknown length unit. Returning original value.";
      @return $length;
    }
  }
  @return $length * $px-per-from-unit / $px-per-to-unit;
}


/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}