.c-confirmation {
  background-color: #AFE4AF;
  border-top: 10px solid #070;
  border-radius: $global-radius;
  padding: 20px;
  margin-top: 1.3rem;
}

.c-confirmation__heading {
  color: $color-brand-purple-dark;
  @include rem(font-size, 14px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;

  @include bp-medium {
    @include rem(font-size, 15px);
  }

  @include bp-large {
    @include rem(font-size, 16px);
  }

  @include bp-jumbo {
    @include rem(font-size, 18px);
  }
}

.c-confirmation__message {
  color: $color-brand-purple-dark;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
