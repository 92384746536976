.c-site-logo {
  background-color: $color-bg;
  border: 1px solid transparent;
  border-top-width: 0;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: block;
  padding: 29px 6px 6px;
  pointer-events: auto;
  max-width: 70px;
  min-width: 70px;
  width: span(2) + gutter();
  z-index: 15;

  @include bp-medium {
    padding: 41px 10px 10px;
    max-width: span(1) + gutter();
  }

  @include bp-full {
    margin-left: -#{gutter()};
  }

  &.can-be-shown {
    transform: translateY(-102%);
    transition: all ease 0.2s;
    top: 0;
    max-width: 70px;

    @include bp-medium-max {
      transform: translateY(-120%);
    }

    @include bp-medium {
      padding: 29px 10px 10px;
    }

    @include bp-large {
      max-width: calc(#{span(1) + gutter()} + 1px);
    }
  }

  &.is-shown {
    @include bp-medium-max {
      .c-header.is-pinned + & {
        transform: translateY(0%);
      }
    }
    
    @include bp-medium {
      transform: translateY(0%);
    }
  }

  .c-toggle-mobile-nav:checked ~ & {
    padding: 40px 15px 15px;
    position: fixed;
    top: 0;
    max-width: 100px;

    @include bp-medium {
      position: static;
    }
  }

  .nav-open & {
    transform: translateY(0%);
  }
}

.c-site-logo__image {
  display: block;
  height: auto;
  width: 100%;
  @include initial-fade-in;
}