$rem-with-px-fallback: true !default;

@mixin rem($property, $values, $use-px-fallback: $rem-with-px-fallback) {
  // Create a couple of empty lists as output buffers.
  $px-values: ();
  $rem-values: ();

  // Ensure $values is a list.
  @if type-of($values) != 'list' {
    $values: join((), $values);
  }

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if type-of($value) == number and not unitless($value) and (unit($value) == px or unit($value) == rem) {
      @if unit($value) == px {
        $px-values: join($px-values, round($value));
        $rem-values: join($rem-values, convert-length($value, rem));
      }
      @else {
        $px-values: join($px-values, round(convert-length($value, px)));
        $rem-values: join($rem-values, $value);
      }
    }
    @else {
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
  }

  // Use pixel fallback for browsers that don't understand rem units.
  @if $use-px-fallback {
    #{$property}: $px-values;
  }

  // Use rem values for everyone else (overrides pixel values).
  #{$property}: $rem-values;
}