.c-panel-vertical-line {
  @include bp-large {
    background-color: $color-text-feature;
    bottom: 134px;
    height: 10000px;
    left: 50%;
    position: absolute;
    top: calc(100% + 20px);
    width: 1px;
    z-index: -1;
  }

  &--to-bottom {
    bottom: 0;
  }
}