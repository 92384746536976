.c-errors {
  background-color: #F6CECE;
  border-top: 10px solid #ae1813;
  border-radius: $global-radius;
  padding: 20px;
  margin-top: 1.3rem;
}

.c-errors__heading {
  margin-bottom: 8px;
  @include rem(font-size, 14px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;

  @include bp-medium {
    @include rem(font-size, 15px);
  }

  @include bp-large {
    @include rem(font-size, 16px);
  }

  @include bp-jumbo {
    @include rem(font-size, 18px);
  }
}

.c-errors__error {
  color: $color-brand-purple;
}

.c-errors__list {
  margin-bottom: 0;
  color: $color-brand-purple;

  li:last-child {
    margin-bottom: 0;
  }
}