@mixin initial-fade-in {
  opacity: 0;
  transition: ease 1s;

  .page-loaded & {
    opacity: 1;
  }

  .no-js & {
   opacity: 1; 
  }
}