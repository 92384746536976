/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  background-color: $color-feature;
  display: inline-block;
  vertical-align: middle;
  font-family: $font-helvetica-medium;
  font-weight: normal;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 5px 26px 4px;
  transition: all ease-in-out 0.15s;
  border-radius: $global-radius;
  border: 1px solid $color-feature;
  color: $color-brand-purple-dark;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-brand-purple-dark;
    text-decoration: none;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
}

.c-btn--outline {
  background-color: transparent;
  padding: 5px 40px;
  color: $color-white;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-feature;
    box-shadow: none;
    color: $color-brand-purple;
  }
}