/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  box-sizing: border-box;
  height: $header-mobile-height;
  left: 0;
  margin: auto;
  max-width: $maxSiteWidth;
  pointer-events: auto;
  position: absolute;
  right: 0;
  z-index: 5;

  @include bp-medium {
    bottom: 0;
    height: auto;
    padding-bottom: 15px;
  }

  .nav-open & {
    @include bp-medium-max {
      height: 100%;
      position: fixed !important;
      transform: translateY(0%) !important;
    }
  }

  &.can-be-shown {
    background-image: $header-gradient;
    box-shadow: 0 9px 21px 0 rgba(0, 0, 0, 0.07);
    padding: 10px 0;
    transition: transform ease 0.2s;
    transform: translateY(-120%);
    top: 0;
    bottom: auto;

    @include bp-medium {
      padding: 12px 0;
    }
  }

  &.not-at-top {
    &.is-unpinned {
      transform: translateY(-120%);
    }
    
    &.is-pinned {
      transform: translateY(0%);
    }
  }
}

.c-header-wrapper {
  left: 0;
  margin: auto;
  max-width: $maxSiteWidth;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  z-index: 100;

  &.is-fixed {
    position: fixed;
  }
}

.c-header__inner,
.c-header-wrapper__inner {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  @include inner-container;
}

.c-header__inner {
  @include initial-fade-in;
}

.c-header-wrapper__inner {
  align-items: flex-start;

  @include bp-medium {
    align-items: flex-end;
  }
}