.c-quote {
  color: $color-brand-purple;
  @include rem(font-size, 20px);
  font-weight: 300;
  line-height: (62 / 48);
  @include rem(margin-bottom, 32px);

  @include bp-medium {
    @include rem(font-size, 28px);
    @include rem(margin-bottom, 40px);
  }

  @include bp-large {
    @include rem(font-size, 36px);
    @include rem(margin-bottom, 48px);
  }

  @include bp-jumbo {
    @include rem(font-size, 48px);
    @include rem(margin-bottom, 64px);
  }
}

.c-quote__author-name {
  color: $color-brand-purple;
  font-weight: bold;
  text-transform: uppercase;
}

.c-quote__author-name,
.c-quote__author-role {
  display: block;

  @include bp-small {
    display: inline-block;
  }
}

.c-quote__author-role {
  color: #7A676D;
}

.c-quote__hyphen {
  color: $color-brand-purple;
  display: none;

  @include bp-small {
    display: inline-block;
  }
}