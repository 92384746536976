$leftPushedGrid: (
  columns: 15,
  gutters: 13px/74px,
  math: fluid,
);

@include with-layout($leftPushedGrid) {
  .c-image-grid {
    @include container;

    @include bp-full {
      margin-left: span(1) + gutter();
    }
  }

  .c-image-grid__item {
    @include rem(margin-bottom, 10px);

    @include bp-small {
      margin-bottom: gutter();
      @include span(7.5);
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      @include bp-small {
        @include span(5);
      }
    }

    &:nth-child(4n) {
      @include bp-small {
        @include span(10);
        margin-right: 0;
      }
    }
  }
}