.c-contact-details {
  margin-bottom: 32px;

  @include bp-medium {
    margin-bottom: 56px;
  }
}

.c-contact-details__icon {
  font-size: 25px;
  color: $color-text-feature;
  margin-right: 8px;
  
  @include bp-medium {
    font-size: 28px;
  }

  @include bp-large {
    font-size: 30px;
  }

  @include bp-xlarge {
    font-size: 32px;
  }

  &.icon-envelope {
    margin-top: -7px;
  }
}

.c-contact-details__line {
  margin-bottom: 16px;

  &.c-contact-details__line--center {
    @include bp-xlarge {
      text-align: center;
    }
  }

  .c-contact-details__icon,
  .c-contact-details__text {
    display: inline-block;
    vertical-align: middle;
  }
}

.c-contact-details__text {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.c-contact-details__content {
  @include bp-medium {
    margin: auto;
    width: 280px;
    text-align: left;
  }

  @include bp-large {
    width: 300px;
  }
  
  @include bp-xlarge {
    width: auto;
  }

  .c-contact-details__line--center & {
    @include bp-xlarge {
      text-align: center;
    }
  }
}