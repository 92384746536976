///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif;

/* Breakpoints
   ========================================================================== */

$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-xlarge: 1280px;
$bp-full: 1600px;
$bp-jumbo: 1920px;

/* Colors
   ========================================================================== */

$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

$color-brand-yellow: #FFCB48;
$color-brand-purple: #50374C;
$color-brand-purple-dark: #27213B;

$color-feature: $color-brand-yellow;

// Text
$color-bg: $color-white;
$color-nav-text: $color-white;
$color-nav-feature: $color-brand-yellow;
$color-text-light: $color-white;
$color-text: #9D9FA2;
$color-text-feature: $color-brand-yellow;
$color-text-heading: $color-brand-purple;
$color-text-grey: #9D9FA2;

// Links
$color-link: $color-brand-purple;
$color-hover: $color-brand-purple;

// Borders
$grey-border: #E1E2E3;

// Fills
$color-fill: $color-very-light-grey;

$susy: (
  columns: 16,
  gutters: 13px/74px,
);

$header-mobile-height: 50px;
$header-gradient: linear-gradient(270deg, #50374C 9%, #27213B 91%);


$maxSiteWidth: 1680px;

$form-input-bg: rgba(80, 55, 76, 0.05);
$form-input-border: 1px solid rgba(122, 103, 109, 0.2);
$form-input-border-focussed: 1px solid $color-feature;
$form-input-placeholder-text-color: $color-text;
$form-input-focussed-text-color: $color-brand-purple;
$form-el-active-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03) inset;