.c-footer-nav__list {
  margin-bottom: 0;
}

.c-footer-nav__link {
  color: $color-nav-text;
  font-family: $font-helvetica-medium;
  font-weight: normal;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-nav-text;
    text-decoration: underline;
  }
}
