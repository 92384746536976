/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-size: 1rem;
  font-family: $font-sans-serif;
  line-height: 2;
  line-height: 1.8;
  min-height: 100%;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

body {
  @include rem(font-size, 14px);

  @include bp-medium {
    @include rem(font-size, 15px);
  }

  @include bp-large {
    @include rem(font-size, 16px);
  }

  @include bp-jumbo {
    @include rem(font-size, 18px);
  }
}