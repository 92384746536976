.c-nav-toggle {
  background-color: $color-nav-feature;
  border-radius: 15px;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  line-height: 1;
  padding: 10px 20px 9px;
  position: absolute;
  text-align: center;
  top: 20px;
  right: 20px;
  z-index: 10;

  .c-header.can-be-shown & {
    top: 10px;
  }

  @include bp-medium {
    display: none;
  }
}

.c-toggle-mobile-nav {
  position: absolute;
  left: -9999em;
  opacity: 0;
}

.c-nav-toggle__text {
  color: $color-brand-purple-dark;
  font-size: 12px;
  font-family: $font-helvetica-medium;
  font-weight: normal;
  text-transform: uppercase;
}
