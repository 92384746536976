.c-panel--grey {
  background-color: #F9F8F8;
}

.c-panel__inner {
  @include inner-container;
  box-sizing: content-box;
  padding-bottom: 40px;
  padding-top: 40px;

  .fade-in & {
    opacity: 1;
    transform: translateY(0);
  }

  @include bp-medium {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  @include bp-large {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  @include bp-xlarge {
    padding-bottom: 134px;
    padding-top: 134px;
  }

  @include bp-jumbo {
    padding-top: 150px;
  }

  .c-panel--pt-md & {
    @include bp-medium {
      padding-top: 120px;
    }
    
    @include bp-large {
      padding-top: 134px;
    }
    
    @include bp-xlarge {
      padding-top: 152px;
    }
  }

  .c-panel--short-pt & {
    @include bp-large {
      padding-top: 80px;
    }

    @include bp-xlarge {
      padding-top: 108px;
    }
  }

  .c-panel--no-pt & {
    padding-top: 0;
  }

  .c-panel--no-pb & {
    @include bp-medium {
      padding-bottom: 0;
    }
  }

  .c-panel--less-pb & {
    @include bp-large {
      padding-bottom: 75px;
    }
  }

  .o-row:last-child [class*=o-col-]:last-child p:last-child {
    margin-bottom: 0;
  }
}

.c-panel--intro {
  background-image: linear-gradient(270deg, #50374C 9%, #27213B 91%);
  background-size: cover;
  background-position: calc(50% + 50px) 50%;
  margin-bottom: -1px;

  @include bp-small {
    background-position: center;
  }

  .c-panel__inner {
    padding-top: 270px;
    padding-bottom: 16px;

    @include bp-medium {
      padding-bottom: 40px;
      padding-top: 320px;
    }

    @include bp-large {
      padding-bottom: 56px;
      padding-top: 486px;
    }

    @include bp-jumbo {
      padding-bottom: 124px;
      padding-top: 646px;
    }
  }
}

.c-panel--quote {
  background-color: #FFCB48;
}

.c-panel--purple {
  background-image: linear-gradient(270deg, #50374C 9%, #27213B 91%);

  .c-panel-heading,
  p {
    color: #fff;
  }

  a {
    color: $color-text-feature;
  }
}

.c-panel--pos-rel .c-panel__inner {
  position: relative;
}

.c-vertical-line-cover {
  background-color: #F9F8F8;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  width: span(2) + gutter();

  @include bp-medium {
    height: 60px;
  }

  @include bp-large {
    height: 100px;
  }

  @include bp-xlarge {
    height: 134px;
  }
}

.c-panel--cover-line {
  .c-panel__inner {
    position: relative;

    &:before {
      background-color: #F9F8F8;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      width: 100%;

      @include bp-medium {
        padding-bottom: 60px;
      }

      @include bp-large {
        padding-bottom: 100px;
      }

      @include bp-xlarge {
        padding-bottom: 134px;
      }
    }
  }
}