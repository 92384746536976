.u-fade-up {
  @include bp-medium {
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s cubic-bezier(0.31, 0.72, 0.43, 1);

    &.u-fade-up--delay {
      transition: transform 0.6s 0.15s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s 0.15s cubic-bezier(0.31, 0.72, 0.43, 1);

      + .u-fade-up--delay {
        transition: transform 0.6s 0.3s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s 0.3s cubic-bezier(0.31, 0.72, 0.43, 1);

        + .u-fade-up--delay {
          transition: transform 0.6s 0.45s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s 0.45s cubic-bezier(0.31, 0.72, 0.43, 1);

          + .u-fade-up--delay {
            transition: transform 0.6s 0.6s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s 0.6s cubic-bezier(0.31, 0.72, 0.43, 1);
          }
        }
      }
    }

    &.u-fade-up--sm-content {
      transform: translateY(50%);
    }

    &.u-fade-up--lg-content {
      transform: translateY(10%);
    }

    &.in-view {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .c-team-member-content,
  .c-team-member-image {
    &.u-fade-up--delay   {
      @include bp-large-max {
        transition: transform 0.6s cubic-bezier(0.31, 0.72, 0.43, 1), opacity 0.6s cubic-bezier(0.31, 0.72, 0.43, 1);  
      }
    }
  }

  .no-js & {
   opacity: 1; 
   transform: translateY(0);
  }
}