/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  box-shadow: 0 0 6px 0 rgba(#000, 0.13);
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: $maxSiteWidth;
}
