.c-panel-sticker {
  font-weight: bold;
  font-size: 70px;
  color: rgba(122,103,109,0.05);
  line-height: 0.8;

  @include bp-medium {
    font-size: 110px;
  }

  @include bp-xlarge {
    font-size: 176px;
  }
}