$halfPopoutPanel: (
  columns: 8,
  gutters: 13px/694px,
  math: fluid,
);


.c-popout-panel {
  background-color: #fff;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  position: relative;

  &--split {
    [class*="o-col"]:first-child .c-popout-panel__inner {
      border-bottom: 1px solid $grey-border;

      @include bp-medium {
        border-bottom-width: 0;
      }
    }

    &:before {
      @include bp-medium {
        background-color: #E1E2E3;
        bottom: 0;
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
      }
    }

    [class*="o-col"] {
      height: 100%;
    }
  }

  [class*="o-col"]:last-child & {
    @include bp-medium-max {
      margin-top: 1.3rem;
    }
  }

  &--push {
    background-color: transparent;
    border: 0;
    box-shadow: none;

    &:before {
      background-color: #fff;
      box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
      content: "";
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      @include bp-large {
        left: span(1) + gutter();
      }
    }

    .o-row {
      position: relative;
      z-index: 1;
    }
  }

  p {
    color: $color-text;
  }

  a {
    color: $color-brand-purple-dark;
  }
}

@include with-layout($halfPopoutPanel) {
  .c-popout-panel--half {
    opacity: 1 !important;
    
    @include bp-medium {
      height: 100%;
    }
    @include bp-large {
      padding-left: span(1) + gutter(1);
      padding-right: span(1)+ gutter(1);
    }
  }
}

.c-popout-panel__inner {
  box-sizing: border-box;
  height: 100%;
  padding: 24px 20px;

  @include bp-small {
    padding: 32px;
  }
  
  @include bp-medium {
    padding: 32px 40px 40px;
  }

  @include bp-large {
    padding: 40px 0 48px;
  }

  @include bp-xlarge {
    padding: 68px 0 86px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.c-popout-panel--half {
  .c-popout-panel__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }

  .c-popout-panel__content {
    display: block;
    flex-grow: 2;
    max-width: 100%;
  }
}

.c-popout-panel__cta {
  margin-top: 26px;
}

.c-popout-panel__heading {
  color: $color-text-heading;
  @include rem(font-size, 20px);
  line-height: 1.5;
  @include rem(margin-bottom, 16px);

  @include bp-medium {
    @include rem(font-size, 24px);
    @include rem(margin-bottom, 24px);
  }

  @include bp-large {
    @include rem(font-size, 32px);
    @include rem(margin-bottom, 40px);
  }

  @include bp-jumbo {
    @include rem(font-size, 36px);
  }
}