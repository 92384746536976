.c-popout-panel-heading {
  @include rem(font-size, 14px);
  color: #fff;
  font-weight: bold;
  line-height: 1.5;
  @include rem(margin-bottom, 32px);
  margin-top: 32px;
  text-transform: uppercase;

  @include bp-medium {
    @include rem(font-size, 15px);
    @include rem(margin-top, 40px);
    margin-bottom: 0;
  }

  @include bp-large {
    @include rem(font-size, 16px);
    @include rem(margin-top, 56px);
  }

  @include bp-jumbo {
    @include rem(font-size, 18px);
  }
}