@import url("//hello.myfonts.net/count/3384a5");

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 300;
    src: url('/assets/fonts/3384A5_0_0.eot');
    src: url('/assets/fonts/3384A5_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/3384A5_0_0.woff2') format('woff2'), url('/assets/fonts/3384A5_0_0.woff') format('woff'), url('/assets/fonts/3384A5_0_0.ttf') format('truetype');
}
@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-style: normal;
    src: url('/assets/fonts/3384A5_2_0.eot');
    src: url('/assets/fonts/3384A5_2_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/3384A5_2_0.woff2') format('woff2'), url('/assets/fonts/3384A5_2_0.woff') format('woff'), url('/assets/fonts/3384A5_2_0.ttf') format('truetype');
}
@font-face {
    font-family: 'HelveticaNeueLTStd-Md';
    src: url('/assets/fonts/3384A5_3_0.eot');
    src: url('/assets/fonts/3384A5_3_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/3384A5_3_0.woff2') format('woff2'), url('/assets/fonts/3384A5_3_0.woff') format('woff'), url('/assets/fonts/3384A5_3_0.ttf') format('truetype');
}

$font-helvetica-medium: 'HelveticaNeueLTStd-Md';

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 700;
    font-style: normal;
    src: url('/assets/fonts/3384A5_1_0.eot');
    src: url('/assets/fonts/3384A5_1_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/3384A5_1_0.woff2') format('woff2'), url('/assets/fonts/3384A5_1_0.woff') format('woff'), url('/assets/fonts/3384A5_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?dty8wr');
  src:  url('/assets/fonts/icomoon.eot?dty8wr#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?dty8wr') format('truetype'),
    url('/assets/fonts/icomoon.woff?dty8wr') format('woff'),
    url('/assets/fonts/icomoon.svg?dty8wr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope:before {
  content: "\e90d";
}
.icon-map-marker:before {
  content: "\e90e";
}
.icon-smartphone:before {
  content: "\e90f";
}
.icon-chair:before {
  content: "\e900";
}
.icon-city:before {
  content: "\e901";
}
.icon-coffee:before {
  content: "\e902";
}
.icon-dropdown-arrow:before {
  content: "\e903";
}
.icon-golf:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-plane:before {
  content: "\e907";
}
.icon-rocket:before {
  content: "\e908";
}
.icon-screen:before {
  content: "\e909";
}
.icon-speedometer:before {
  content: "\e90a";
}
.icon-thongs:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90c";
}
