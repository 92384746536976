.nav-open {
  overflow-y: hidden;

  @include bp-medium {
    overflow-y: visible;
  }
}

.c-site-nav {
  background-image: $header-gradient;
  bottom: 100%;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: -100%;
  transition: opacity ease 0.3s;
  z-index: 2;

  @include bp-medium {
    background-image: none;
    flex-grow: 1;
    position: static;
    text-align: right;
  }

  .c-toggle-mobile-nav:checked ~ & {
    bottom: 0;
    top: 0;
  }
}

.c-site-nav__list {
  bottom: 0;
  padding-top: 100px;
  position: absolute;
  left: 20px;
  right: 20px;
  margin-bottom: 0;
  text-align: center;
  transition: transform ease 0.3s;
  top: $header-mobile-height;

  @include bp-medium {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    padding-top: 0;
    position: static;
  }

  @include bp-full {
    margin-right: calc(#{span(1) + gutter()} - 25px);
    margin-right: 0;
  }

  .c-site-nav__list-item:last-child {
    @include bp-medium-max {
      margin-bottom: 32px;
    }
  }
}

.c-site-nav__list-item {
  display: block;
  // opacity: 0;
  margin: 10px 0;
  transform: translateX(100%);
  transition: all ease 0.3s;

  @include bp-medium {
    margin: 0 2px;
    opacity: 1;
    transform: translateX(0);
  }

  @include bp-large {
    margin: 0 5px;
  }

  @include bp-xlarge {
    margin: 0 17px;
    text-align: left;
    display: inline-block;
  }

  &:last-child {
    margin-right: 0;
  }

  .c-toggle-mobile-nav:checked ~ .c-site-nav & {
    // opacity: 1;
    transform: translateX(0%);
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition-delay: (0.1s + 0.05s * $i);
    }
  }
}

.c-site-nav__link {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  color: $color-nav-text;
  display: inline-block;
  font-family: $font-helvetica-medium;
  font-weight: normal;
  @include rem(font-size, 18px);
  letter-spacing: 0.23px;
  line-height: 1;
  padding: 5px 0;
  text-decoration: none;
  transition: all ease 0.3s;

  @include bp-medium {
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    @include rem(font-size, 15px);
    padding: 8px 10px;
  }

  @include bp-large {
    @include rem(font-size, 16px);
    padding: 12px 25px;
  }

  @include bp-jumbo {
    padding: 12px 25px;
    @include rem(font-size, 18px);
  }

  &:hover,
  &:focus {
    outline: 0;
    
    @include bp-medium {
      background-color: rgba(#fff, 0.1);
      border: 1px solid rgba(#fff, 0.33);
      color: $color-nav-text;

      .c-header.can-be-shown & {
        background-color: rgba(#fff, 0.05);
      }
    }
  }

  &:active {
    @include bp-medium {
      border: 1px solid rgba($color-nav-feature, 0.5);
    }
  }

  &.is-active {
    border-color: $color-nav-feature;
  }

  .c-header.can-be-shown & {
    @include bp-large {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}